import { Address } from '../types/address';

import { capitalizeFirstLetter } from './text';

export const addressToText = (address: Address): string =>
  [
    capitalizeFirstLetter(address.street),
    capitalizeFirstLetter(address.city),
    address.state,
    capitalizeFirstLetter(address.zipcode),
  ]
    .filter(v => !!v)
    .join(', ');
