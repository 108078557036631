import { Address } from '../types/address';
import { Feature } from '../types/mapbox';

export const featureToAddress = (feature: Feature): Address => ({
  street: feature.properties.context.address.name,
  city: feature.properties.context.place.name,
  state: feature.properties.context.region.region_code,
  county: feature.properties.context.district.name,
  zipcode: feature.properties.context.postcode.name,
  country: feature.properties.context.country.country_code,
});
