import React, { useMemo } from 'react';

import { Property } from '../../types/property';
import { BaseProps } from '../../types/props';
import { numberToSqFt, numberToUSD, toNumberOrNull } from '../../utils/number';
import { capitalizeFirstLetter } from '../../utils/text';

import PropertyCard from './PropertyCard';
import PropertyCardAddress from './PropertyCardAddress';
import S from './PropertyDetails.styles';

interface Props extends BaseProps {
  property?: Property;
  isLoading?: boolean;
}

const PropertyDetails: React.FC<Props> = ({ property, isLoading, ...baseProps }) => {
  const propertyAttributes = useMemo(
    () => property?.precisely?.propertyAttributes,
    [property?.precisely?.propertyAttributes]
  );

  const propertyType = useMemo(() => {
    const landUseValue = propertyAttributes?.landUse?.value;

    if (!landUseValue) return null;

    return capitalizeFirstLetter(landUseValue);
  }, [propertyAttributes]);

  return (
    <S.CardsGrid {...baseProps}>
      <PropertyCardAddress property={property} isLoading={isLoading} isShowingImage />

      <PropertyCard title="Property Details" isLoading={isLoading} loadingLines={3}>
        {!!propertyType && (
          <>
            {propertyType}
            <br />
          </>
        )}
        {toNumberOrNull(propertyAttributes?.bedrooms) || 'N/A'} Bedroom ·{' '}
        {toNumberOrNull(propertyAttributes?.baths) || 'N/A'} Bath
        <br />
        Built in {propertyAttributes?.builtYear || 'N/A'}
      </PropertyCard>

      <PropertyCard title="Building Size" isLoading={isLoading}>
        {numberToSqFt(property?.building_sq_ft) ?? 'Unknown'}
      </PropertyCard>

      <PropertyCard title="Property Size" isLoading={isLoading}>
        {numberToSqFt(property?.property_sq_ft) ?? 'Unknown'}
      </PropertyCard>

      <PropertyCard title="Last Sold Price" isLoading={isLoading}>
        {numberToUSD(Number(propertyAttributes?.taxSalesPrice)) ??
          numberToUSD(Number(propertyAttributes?.priorSalesPrice)) ??
          'Unknown'}
      </PropertyCard>

      <PropertyCard title="Tax by Year" isLoading={isLoading}>
        {numberToUSD(Number(propertyAttributes?.taxAmount)) ?? 'Unknown'}
      </PropertyCard>
    </S.CardsGrid>
  );
};

export default PropertyDetails;
